import { FILE } from '../actions/ActionTypes';
import { updateReducer } from '../utils/updateReducer';
import { initReducer } from '../utils/initReducer';

const INITIAL_STATE = {};

export default (
  state = {
    ...initReducer(FILE),
    ...INITIAL_STATE,
  },
  action,
) => {
  switch (action.type) {
    case FILE.UPLOAD_FILE.REQUEST:
      return updateReducer(state, action);
    case FILE.UPLOAD_FILE.RESPONSE:
      return updateReducer(state, action);
    case FILE.UPLOAD_FILE.CANCEL:
      return updateReducer(state, action);

    default:
      return state;
  }
}
