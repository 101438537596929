import React from 'react';
import PropTypes from 'prop-types';

function ExternalLink ({
  to,
  className,
  style,
  children,
}) {
  return (
    <a
      href={to}
      className={className}
      style={style}
      target='_blank'
      rel='noreferrer noopener'
    >
      {children}
    </a>
  );
};

ExternalLink.defaultProps = {
  className: '',
  style: {},
};

ExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ExternalLink;
