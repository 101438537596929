import * as ActionTypes from './ActionTypes';

export function authorize() {
  return {
    type: ActionTypes.USER.AUTH.REQUEST,
  };
}

export function getProfile(uid) {
  return {
    type: ActionTypes.USER.GET_PROFILE.REQUEST,
    payload: uid,
  };
}

export function updateProfile(data) {
  return {
    type: ActionTypes.USER.UPDATE_PROFILE.REQUEST,
    payload: data,
  };
}

export function updateGroup(uid, data) {
  return {
    type: ActionTypes.USER.UPDATE_GROUP.REQUEST,
    payload: {
      uid,
      data,
    },
  };
}

export function setAuthState(user) {
  return {
    type: ActionTypes.USER.SET_AUTH_STATE,
    payload: user,
  };
}

export function getCurriculumList() {
  return {
    type: ActionTypes.CURRICULUM.GET_LIST.REQUEST,
  };
}

export function createCurriculum(key) {
  return {
    type: ActionTypes.CURRICULUM.CREATE_CURRICULUM.REQUEST,
    payload: key,
  };
}

export function getCurriculumDetail(key) {
  return {
    type: ActionTypes.CURRICULUM.GET_DETAIL.REQUEST,
    payload: key,
  };
}

export function updateCurriculumDetail(key, data) {
  return {
    type: ActionTypes.CURRICULUM.UPDATE_DETAIL.REQUEST,
    payload: {
      key,
      data,
    },
  };
}

export function getTeam() {
  return {
    type: ActionTypes.TEAM.GET_LIST.REQUEST,
  };
}

export function uploadFile(name, file) {
  return {
    type: ActionTypes.FILE.UPLOAD_FILE.REQUEST,
    payload: {
      name,
      file,
    },
  };
};
