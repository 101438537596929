import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { auth } from './domain';
import { setAuthState, getProfile } from './actions';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Layout from './views/layout';
import Spinner from './components/spinner';

const Landing = React.lazy(() => import('./views/landing'));
// const FeaturedCurriculum = React.lazy(() => import('./views/featured-curriculum'));
const Team = React.lazy(() => import('./views/team'));
const NotFound = React.lazy(() => import('./views/not-found'));
const Auth = React.lazy(() => import('./views/auth'));
const Profile = React.lazy(() => import('./views/profile'));
const CurriculumEditor = React.lazy(() => import('./views/curriculum-editor'));

class App extends Component {
  static propTypes = {
    setAuthState: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.onAuthStateChanged();
  }

  onAuthStateChanged = () => {
    auth.onAuthStateChanged((user) => {
      this.props.setAuthState(user);
      this.props.getProfile(user.uid);
    });
  }

  onAuth = View => (props = {}) => {
    // if (!auth.currentUser) {
    //   return (
    //     <Redirect to='/d/auth' />
    //   );
    // }

    return <View {...props} />;
  }

  render() {
    return (
      <Router>
        <Layout>
          <Suspense
            fallback={(
              <div className='layout-spinner'>
                <Spinner rect={48} />
              </div>
            )}
            maxDuration={1500}
          >
            <Switch>
              <Route path='/' exact component={Landing} />
              {/* <Route path='/curriculum' component={Landing} />
              <Route path='/curriculum/:id' component={Landing} /> */}
              <Route path='/team' exact component={Team} />
              <Route path='/d/auth' exact component={Auth} />
              <Route path='/d/profile' exact component={this.onAuth(Profile)} />
              <Route path='/d/curriculum' exact component={this.onAuth(CurriculumEditor)} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </Router>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setAuthState,
  getProfile,
}, dispatch);

export default connect(null, mapDispatchToProps)(App);
