import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { debounce } from '../../utils/debounce';

import './LiveChat.scss';

const APPENDIX = 35;

class LiveChat extends PureComponent {
  constructor(props) {
    super(props);

    this.node = React.createRef();

    this.state = {
      style: null,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollDocument);
    window.addEventListener('resize', this.handleResizeDocument);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollDocument);
    window.removeEventListener('resize', this.handleResizeDocument);
  }

  handleScrollDocument =() => {
    const { node } = this;
    const footer = document.getElementsByTagName('footer')[0];

    if (!footer || !node) {
      return;
    }

    const { offsetTop } = footer;
    const { clientHeight } = this.node.current;

    if (window.scrollY >= (offsetTop - window.innerHeight)) {
      const position = offsetTop - clientHeight - APPENDIX;

      this.setState({
        style: {
          position: 'absolute',
          top: `${position}px`,
        },
      });
    } else if (this.state.style) {
      this.setState({ style: null });
    }
  }

  handleResizeDocument = debounce(this.handleScrollDocument, 100);

  render() {
    return (
      <div
        id='live-chat'
        ref={this.node}
        style={this.state.style}
      >
        <i className='material-icons'>
          chat_bubble
        </i>
      </div>
    );
  }
}

export default LiveChat;
