import React from 'react';

import ExternalLink from '../../components/external-link';

import FacebookIcon from '../../assets/images/ic-footer-facebook.svg';
import InstagramIcon from '../../assets/images/ic-footer-instagram.svg';
import MailIcon from '../../assets/images/ic-footer-mail.svg';

import './Footer.scss';

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__copyright'>
        &copy; 2015 - 설리번교육연구소
      </div>
      <div className='footer__outlink'>
        <ul className='footer__service-terms'>
          <li>
            <ExternalLink to='http://sullivan-project-terms.surge.sh/privacy.html'>
              개인정보 취급방침
            </ExternalLink>
          </li>
          <li>
            <ExternalLink to='http://sullivan-project-terms.surge.sh/service.html'>
              이용약관
            </ExternalLink>
          </li>
        </ul>
        <ul className='footer__contact'>
          <li>
            <ExternalLink to='https://fb.com/sullivanproject.in'>
              <img
                className='lazyload'
                data-src={FacebookIcon}
                alt='Facebook'
              />
            </ExternalLink>
          </li>
          <li>
            <ExternalLink to='https://instagr.am/sullivanproject'>
              <img
                className='lazyload'
                data-src={InstagramIcon}
                alt='Instagram'
              />
            </ExternalLink>
          </li>
          <li>
            <a href='mailto:contact@sullivanproject.in'>
              <img
                className='lazyload'
                data-src={MailIcon}
                alt='contact@sullivanproject.in'
              />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
