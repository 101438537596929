import { ASYNC } from '../constants';
import { createAction } from '../utils/createAction';

export const USER = createAction(
  'data/user',
  {
    AUTH: ASYNC,
    GET_PROFILE: ASYNC,
    UPDATE_PROFILE: ASYNC,
    UPDATE_GROUP: ASYNC,
    SET_AUTH_STATE: null,
  },
);

export const FILE = createAction(
  'data/file',
  {
    UPLOAD_FILE: ASYNC,
  },
);

export const CURRICULUM = createAction(
  'features/curriculum',
  {
    GET_LIST: ASYNC,
    GET_DETAIL: ASYNC,
    CREATE_CURRICULUM: ASYNC,
    UPDATE_DETAIL: ASYNC,
  },
);

export const TEAM = createAction(
  'features/team',
  {
    GET_LIST: ASYNC,
  },
);
