import WebFont from 'webfontloader';

const WebFontConfig = {
  custom: {
    families: [
      'Objectivity',
      'Spoqa Han Sans',
      'Material Icons',
    ],
    urls: [
      '/resource/webfont/Objectivity.css',
      'https://cdnjs.cloudflare.com/ajax/libs/spoqa-han-sans/2.1.1/css/SpoqaHanSans-kr.min.css',
      'https://fonts.googleapis.com/icon?family=Material+Icons',
    ],
  },
};

WebFont.load(WebFontConfig);
