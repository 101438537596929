import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import UserReducer from './UserReducer';
import TeamReducer from './TeamReducer';
import CurriculumReducer from './CurriculumReducer';
import FileReducer from './FileReducer';

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,

  user: UserReducer,
  team: TeamReducer,
  curriculum: CurriculumReducer,
  file: FileReducer,
});

export default rootReducer;
