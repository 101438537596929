import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import './Button.scss';

function Button({
  children,
  onClick,
  className,
  component,
  ...props
}) {
  const Component = component;

  return (
    <Component
      type='button'
      className={cx('btn', className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </Component>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};

Button.defaultProps = {
  children: null,
  onClick: null,
  className: '',
  component: 'button',
};

export default Button;
