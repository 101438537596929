export const ASYNC = 'ASYNC';

export const STATUS = {
  REQUEST: 'REQUEST',
  RESPONSE: 'RESPONSE',
  CANCEL: 'CANCEL',
};

export const KEY = {
  ESC: 'Escape',
  ENTER: 'Enter',
  TAB: 'Tab',
  SPACE: ' ',

  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',

  HOME: 'Home',
  END: 'End',
};

export const FOCUSABLE_ELEMENT =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
