import { USER } from '../actions/ActionTypes';
import { updateReducer } from '../utils/updateReducer';
import { initReducer } from '../utils/initReducer';

const INITIAL_STATE = {
  isAuthorized: false,
  data: {
    uid: '',
    displayName: '',
    photoURL: '',
    permission: '',
    division: '',
    introduction: '',
    entryYear: -1,
    group: {},
  },
};

export default (
  state = {
    ...initReducer(USER),
    ...INITIAL_STATE,
  },
  action,
) => {
  switch (action.type) {
    case USER.AUTH.REQUEST:
      return updateReducer(state, action);
    case USER.AUTH.RESPONSE:
      if (!action.error) {
        const { displayName, photoURL, uid } = action.payload;

        return {
          ...updateReducer(state, action),
          isAuthorized: true,
          data: {
            ...state.data,
            uid,
            displayName,
            photoURL,
          },
        };
      }
      return updateReducer(state, action);
    case USER.AUTH.CANCEL:
      return updateReducer(state, action);

    case USER.SET_AUTH_STATE: {
      if (!action.payload) {
        return {
          ...state,
          ...INITIAL_STATE,
        };
      }

      const { displayName, photoURL, uid } = action.payload;

      return {
        ...state,
        isAuthorized: true,
        data: {
          ...state.data,
          uid,
          displayName,
          photoURL,
        },
      }
    }

    case USER.GET_PROFILE.REQUEST:
      return updateReducer(state, action);
    case USER.GET_PROFILE.RESPONSE: {
      if (!action.error) {
        return {
          ...updateReducer(state, action),
          data: {
            ...state.data,
            ...action.payload,
          },
        };
      }
      return updateReducer(state, action);
    }
    case USER.GET_PROFILE.CANCEL:
      return updateReducer(state, action);

    case USER.UPDATE_PROFILE.REQUEST:
      return updateReducer(state, action);
    case USER.UPDATE_PROFILE.RESPONSE: {
      if (!action.error) {
        return {
          ...updateReducer(state, action),
          data: {
            ...state.data,
            ...action.payload.data,
          },
        };
      }
      return updateReducer(state, action);
    }
    case USER.UPDATE_PROFILE.CANCEL:
      return updateReducer(state, action);

    case USER.UPDATE_GROUP.REQUEST:
      return updateReducer(state, action);
    case USER.UPDATE_GROUP.RESPONSE: {
      if (!action.error) {
        return {
          ...updateReducer(state, action),
          data: {
            ...state.data,
            group: action.payload.data,
          },
        };
      }
      return updateReducer(state, action);
    }
    case USER.UPDATE_GROUP.CANCEL:
      return updateReducer(state, action);

    default:
      return state;
  }
}
