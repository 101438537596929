import { call, put } from 'redux-saga/effects';

import { USER } from '../actions/ActionTypes';
import { auth, database, provider } from '../domain';

function setUser({
  uid,
  displayName,
  photoURL,
}) {
  return {
    type: USER.AUTH.RESPONSE,
    payload: {
      uid,
      displayName,
      photoURL,
    },
  };
}

export function* authorize(action) {
  try {
    const result = yield call([auth, auth.signInWithPopup], provider);

    const {
      user,
      // credential: { accessToken },
    } = result;

    yield put(setUser(user));
  } catch ({ code, message }) {
    console.error('There was an error while authorizing.', code, message);

    yield put({
      type: USER.AUTH.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}

export function* getProfile(action) {
  try {
    const ref = database.ref(`member/${action.payload}`);
    const result = yield call([ref, ref.once], 'value');

    yield put({
      type: USER.GET_PROFILE.RESPONSE,
      payload: result.val(),
    });

  } catch ({ code, message }) {
    console.error('There was an error while fetching the data.', code, message);

    yield put({
      type: USER.GET_PROFILE.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}

export function* updateProfile(action) {
  const data = action.payload;

  try {
    const { currentUser } = auth;
    yield call([currentUser, currentUser.updateProfile], data);

    const ref = database.ref(`member/${data.uid}`);
    const result = yield call([ref, ref.set], data);

    yield put({
      type: USER.UPDATE_PROFILE.RESPONSE,
      payload: {
        data,
        result,
      },
    });
  } catch ({ code, message }) {
    console.error('There was an error while updating the data.', code, message);

    yield put({
      type: USER.UPDATE_PROFILE.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}

export function* updateGroup(action) {
  try {
    const { uid, data } = action.payload;

    const ref = database.ref(`member/${uid}/group`);
    const result = yield call([ref, ref.set], data);

    yield put({
      type: USER.UPDATE_GROUP.RESPONSE,
      payload: {
        data,
        result,
      },
    });
  } catch ({ code, message }) {
    console.error('There was an error while updating the data.', code, message);

    yield put({
      type: USER.UPDATE_GROUP.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}
