import { takeEvery, takeLatest } from 'redux-saga/effects';

import * as ActionTypes from '../actions/ActionTypes';
import * as UserSagas from './UserSagas';
import * as CurriculumSagas from './CurriculumSagas';
import * as TeamSagas from './TeamSagas';
import * as FileSagas from './FileSagas';

function* config() {
  // UserSagas
  yield takeEvery(
    ActionTypes.USER.AUTH.REQUEST,
    UserSagas.authorize,
  );

  yield takeEvery(
    ActionTypes.USER.GET_PROFILE.REQUEST,
    UserSagas.getProfile,
  );

  yield takeLatest(
    ActionTypes.USER.UPDATE_PROFILE.REQUEST,
    UserSagas.updateProfile,
  );

  yield takeLatest(
    ActionTypes.USER.UPDATE_GROUP.REQUEST,
    UserSagas.updateGroup,
  );

  // TeamSagas
  yield takeEvery(
    ActionTypes.TEAM.GET_LIST.REQUEST,
    TeamSagas.getTeam,
  );

  // CurriculumSagas
  yield takeEvery(
    ActionTypes.CURRICULUM.GET_LIST.REQUEST,
    CurriculumSagas.getCurriculumList,
  );

  yield takeEvery(
    ActionTypes.CURRICULUM.CREATE_CURRICULUM.REQUEST,
    CurriculumSagas.createCurriculum,
  );

  yield takeLatest(
    ActionTypes.CURRICULUM.GET_DETAIL.REQUEST,
    CurriculumSagas.getCurriculumDetail,
  );

  yield takeLatest(
    ActionTypes.CURRICULUM.UPDATE_DETAIL.REQUEST,
    CurriculumSagas.updateCurriculumDetail,
  );

  // FileSagas
  yield takeEvery(
    ActionTypes.FILE.UPLOAD_FILE.REQUEST,
    FileSagas.uploadFile,
  );
}

export default config;
