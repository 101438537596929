import { ASYNC, STATUS } from '../constants';

export function createAction(dimension = '', actionEntry = {}) {
  return Object.entries(actionEntry)
    .reduce((obj, [actionType, attr]) => {
      const uniqueKey = `${dimension}/${actionType}`;

      if (attr === ASYNC) {
        const action = Object.values(STATUS).reduce((entry, status) => {
          return {
            ...entry,
            [status]: `${uniqueKey}#${status}`,
          };
        }, {});

        return {
          ...obj,
          [actionType]: action,
        };
      }

      return {
        ...obj,
        [actionType]: uniqueKey,
      };
    }, {});
}
