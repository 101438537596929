import { call, put } from 'redux-saga/effects';

import { TEAM } from '../actions/ActionTypes';
import { database } from '../domain';

export function* getTeam(action) {
  try {
    const ref = database.ref('member');
    const result = yield call([ref, ref.once], 'value');

    yield put({
      type: TEAM.GET_LIST.RESPONSE,
      payload: result.val(),
    });
  } catch ({ code, message }) {
    console.error('There was an error while fetching the data.', code, message);

    yield put({
      type: TEAM.GET_LIST.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}
