import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { loadingBarMiddleware } from 'react-redux-loading-bar';

import rootReducer from './index';
import sagas from '../sagas';

const sagaMiddleware = createSagaMiddleware();
const progressBarMiddleware = loadingBarMiddleware({
  promiseTypeSuffixes: ['#REQUEST', '#RESPONSE', '#CANCEL'],
});

export default function () {
  let composeEnhancers = compose;

  const middleware = [
    sagaMiddleware,
    progressBarMiddleware,
  ];

  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const enhancers = composeEnhancers(applyMiddleware(...middleware));
  const store = createStore(rootReducer, enhancers);

  sagaMiddleware.run(sagas);

  return store;
}
