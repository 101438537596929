import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  withRouter,
  NavLink,
  Link,
} from 'react-router-dom';

import cx from 'classnames';

import Button from '../../components/button';
import ExternalNavLink from '../../components/external-link';

import Logo from '../../assets/images/logo.png';
import './Navigation.scss';

const SCROLL_LINE = 60;

class Navigation extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.node = React.createRef();

    this.state = {
      isScrollTop: false,
      isSidebarOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollDocument);
    window.addEventListener('resize', this.handleResizeDocument);
    document.addEventListener('click', this.handleClickDocument);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !==
      this.props.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollDocument);
    window.removeEventListener('resize', this.handleResizeDocument);
    document.removeEventListener('click', this.handleClickDocument);
  }

  handleScrollDocument = () => {
    const { scrollY } = window;

    if (scrollY < SCROLL_LINE && this.state.isScrollTop) {
      this.setState({ isScrollTop: false });
      return;
    }

    if (scrollY >= SCROLL_LINE && !this.state.isScrollTop) {
      this.setState({ isScrollTop: true });
    }
  }

  handleResizeDocument = () => {
    if (this.state.isSidebarOpen) {
      this.setState({ isSidebarOpen: false });
    }
  }

  handleClickDocument = ({ target }) => {
    if (
      !this.node ||
      !this.state.isSidebarOpen
    ) {
      return;
    }

    if (
      !this.node.current.contains(target) ||
      target.nodeName === 'A'
    ) {
      this.setState({ isSidebarOpen: false });
    }
  }

  handleClickOpenMenu = () => {
    this.setState({ isSidebarOpen: true });
  }

  render() {
    return (
      <nav
        ref={this.node}
        className={cx(
          'navigation',
          { scroll: this.state.isScrollTop },
        )}
      >
        <Link to='/'>
          <img
            className='navigation__logo lazyload'
            data-src={Logo}
            alt='설리번 프로젝트'
          />
        </Link>
        <button
          className='navigation__toggler'
          onClick={this.handleClickOpenMenu}
          aria-label='링크 목록 열기'
        >
          <i className='material-icons'>
            menu
          </i>
        </button>
        <ul
          className={cx(
            'navigation__list',
            { open: this.state.isSidebarOpen },
          )}
        >
          <li className='navigation__item'>
            <Link to='/'>
              홈
            </Link>
          </li>
          <li className='navigation__item'>
            <NavLink to='/curriculum' activeClassName='on'>
              커리큘럼
            </NavLink>
          </li>
          <li className='navigation__item'>
            <NavLink to='/team' activeClassName='on'>
              팀 소개
            </NavLink>
          </li>
          <li className='navigation__item'>
            <ExternalNavLink
              to='https://drive.google.com/file/d/0B8lOtOBbOgeLMnZmUWpGRFU5aHo1Nk9CMHV4Sy1ZSXBiblFr/view?usp=sharing'>
              후원하기
            </ExternalNavLink>
          </li>
          <li className='navigation__action'>
            <Button
              component='a'
              aria-label='지원하기'
            >
              지원하기
            </Button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter(Navigation);
