import React from 'react';
import PropTypes from 'prop-types';

import './Spinner.scss';

function Spinner ({ rect, ...props }) {
  return (
    <div
      className='spinner-container'
      {...props}
    >
      <svg
        className='spinner'
        width={`${rect}px`}
        height={`${rect}px`}
        viewBox='0 0 66 66'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          className='path'
          fill='none'
          strokeWidth='6'
          strokeLinecap='round'
          cx='33'
          cy='33'
          r='30'
        />
      </svg>
    </div>
  );
};

Spinner.propTypes = {
  rect: PropTypes.number,
};

Spinner.defaultProps = {
  rect: 24,
};

export default Spinner;
