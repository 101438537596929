export function initReducer(actionType) {
  return Object.keys(actionType).reduce((obj, type) => {
    if (typeof actionType[type] !== 'object') {
      return obj;
    }

    return {
      ...obj,
      [type]: {
        type,
        isFetching: false,
      },
    };
  }, {});
}
