import { call, put } from 'redux-saga/effects';

import { USER, CURRICULUM } from '../actions/ActionTypes';
import { auth, database } from '../domain';

export function* getCurriculumList(action) {
  try {
    const ref = database.ref('featured');
    const result = yield call([ref, ref.once], 'value');

    yield put({
      type: CURRICULUM.GET_LIST.RESPONSE,
      payload: result.val() || {},
    });
  } catch ({ code, message }) {
    console.error('There was an error while fetching the data.', code, message);

    yield put({
      type: CURRICULUM.GET_LIST.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}

export function* createCurriculum(action) {
  try {
    const { key } = database.ref().child('featured').push();
    const ref = database.ref(`featured/${key}`);

    const data = action.payload;
    const result = yield call([ref, ref.set], data);

    yield put({
      type: CURRICULUM.CREATE_CURRICULUM.RESPONSE,
      payload: {
        key,
        data,
        result,
      },
    });
  } catch ({ code, message }) {
    console.error('There was an error while creating the curriculum.', code, message);

    yield put({
      type: CURRICULUM.CREATE_CURRICULUM.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}

export function* getCurriculumDetail(action) {
  try {
    const key = action.payload;

    const ref = database.ref(`curriculum/${key}`);
    const result = yield call([ref, ref.once], 'value');

    const teamRef = database
      .ref('member')
      .orderByChild(`group/${key}`)
      .equalTo(true);
    const teamResult = yield call([teamRef, teamRef.once], 'value');

    yield put({
      type: CURRICULUM.GET_DETAIL.RESPONSE,
      payload: {
        key,
        data: result.val(),
        team: Object.values(teamResult.val()),
      },
    });
  } catch ({ code, message }) {
    console.error('There was an error while fetching the data.', code, message);

    yield put({
      type: CURRICULUM.GET_DETAIL.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}

export function* updateCurriculumDetail(action) {
  try {
    const { key, data } = action.payload;
    const ref = database.ref(`curriculum/${key}`);

    const result = yield call([ref, ref.set], data);

    yield put({
      type: CURRICULUM.UPDATE_DETAIL.RESPONSE,
      payload: {
        key,
        data,
        result,
      },
    });
  } catch ({ code, message }) {
    console.error('There was an error while updating the data.', code, message);

    yield put({
      type: CURRICULUM.UPDATE_DETAIL.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}
