import { STATUS } from '../constants';

export function updateReducer(state, { type, ...action }) {
  const field = type.split(/\/|#/g);

  const status = field.pop();
  const actionType = field.pop();

  return {
    ...state,
    [actionType]: {
      ...action,
      isFetching: status === STATUS.REQUEST,
      ok: !action.error,
    },
  };
}
