import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LoadingBar from 'react-redux-loading-bar';

import Navigation from '../navigation';
import Footer from '../footer';
import LiveChat from '../live-chat';

function Layout({ children }) {
  return (
    <Fragment>
      <LoadingBar className='loading-bar' />
      <Navigation />
      {children}
      <Footer />
      <LiveChat />
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
