import { call, put } from 'redux-saga/effects';

import { FILE } from '../actions/ActionTypes';
import { storage } from '../domain';

export function* uploadFile(action) {
  const { name, file } = action.payload;

  try {
    const ref = storage.ref().child(name);
    const result = yield call([ref, ref.put], file);

    const url = yield call([ref, ref.getDownloadURL]);

    yield put({
      type: FILE.UPLOAD_FILE.RESPONSE,
      payload: {
        url,
        result,
      },
    });
  } catch ({ code, message }) {
    console.error('There was an error while uploading the file.', code, message);

    yield put({
      type: FILE.UPLOAD_FILE.RESPONSE,
      error: {
        code,
        message,
      },
    });
  }
}
