import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
  projectId: 'eden-east',
  apiKey: 'AIzaSyAOiPReeLhocTQWORBdwmTUYnggjcylleY',
  authDomain: 'eden-east.firebaseapp.com',
  databaseURL: 'https://eden-east.firebaseio.com/',
  storageBucket: 'eden-east.appspot.com',
};

firebase.initializeApp(config);

export const provider = new firebase.auth.GithubAuthProvider();
export const auth = firebase.auth();
export const database = firebase.database();
export const storage = firebase.storage();

export default firebase;
