import { TEAM } from '../actions/ActionTypes';
import { updateReducer } from '../utils/updateReducer';
import { initReducer } from '../utils/initReducer';

const INITIAL_STATE = {
  list: [],
};

export default (
  state = {
    ...initReducer(TEAM),
    ...INITIAL_STATE,
  },
  action,
) => {
  switch (action.type) {
    case TEAM.GET_LIST.REQUEST:
      return updateReducer(state, action);
    case TEAM.GET_LIST.RESPONSE:
      return {
        ...updateReducer(state, action),
        list: Object.values(action.payload),
      };
    case TEAM.GET_LIST.CANCEL:
      return updateReducer(state, action);

    default:
      return state;
  }
}
